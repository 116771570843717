import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import has from 'lodash/has'

import { DEFAULT_CURRENCY, } from '@/config/constants.js'
import translations from '@/config/translations.js'
import { Url, resources, } from '@/config/url.js'

import toggleStore from '@/state/toggleStore.js'

import countryCodes from './countryCodes.js'
import rates from './rates.json'
import { ToastProgrammatic, SnackbarProgrammatic, } from 'buefy'

dayjs.extend(relativeTime)

const helpers = {
    I18N: translations.I18N.global,
    breakpoints: {
        smallest: 320,
        mobile: 480,
        small: 767,
        medium: 992,
        headerResponsive: 1090, // define by our Bulma template
        large: 1200,
        huge: 2000,
    },
    maxNumberOfDaysAgo: 7,
    /**
     * Tells if device is mobile
     * @returns {boolean}
     */
    isMobile: () => window.innerWidth <= helpers.breakpoints.small,

    isHeaderResponsive: () => window.innerWidth <= helpers.breakpoints.headerResponsive,

    /**
     * Return VAT int as a decimal with %
     * 196 --> 19.6
     * @param  {Number} int number to turn into decimal
     * @returns {String}
     */
    formatVAT: (int) => `${int / 10}%`,
    /**
     * If date is smaller than our maxNumberOfDaysAgo (in days)
     * @param {String} date (Iso date)
     * @returns {Bool}
     */
    isRecentDate (date) {
        const now = dayjs()
        const then = dayjs(date)
        const distanceInDays = now.diff(then, 'days')

        return distanceInDays < helpers.maxNumberOfDaysAgo
    },
    /**
     * Return Time ago phrase with locale
     * @param {String} date Iso date
     * @param {String} absoluteFormat Used if date is too old
     * @returns {String}
     */
    timeAgo: (date, absoluteFormat = 'DD/MM/YYYY HH:mm') => {
        if (helpers.isRecentDate(date)) return dayjs(date).fromNow() // Relative date (recent)

        return dayjs(date).format(absoluteFormat) // Absolute date (old)
    },
    /**
     * Display readable date (without relative format)
     * @param {String} date Date to format
     * @param {Bool} complete Display full date translated by dayjs locale (eg: "thuesday 11 december 2018")
     * @returns {String} Readable date
     */
    formatDate: (date, complete = false) => dayjs(date).format(complete
        ? 'LLLL:ss'
        : 'lll:ss'),

    /**
     * Get a date format according to a given locale
     * To use it outside a Vue context
     * @param {String} locale "en", "fr", ...
     * @param {Boolean} withSec If True adds seconds to the formated date
     * @returns {string} the time formated with seconds or not
     */
    getLocaleDateFormat: (locale, withSec = false) => {
        let format = 'DD/MM/YYYY HH:mm' // To translate according to locale when needed

        if (withSec) format += ':ss'

        return format
    },

    /**
     * Turns Emi3 into Url
     * @param {String} emi3 emi3
     * @returns {String}
     */
    emi3ToUrl: (emi3) => {
        if (emi3) {
            // let url = emi3.slice(0,2) + '/' + emi3.slice(2)
            return emi3
        }
    },
    /**
     * Format Emi3 according its type
     * @param  {String} emi3 emi3
     * @param  {String} type type of emi3
     * @returns {String}
     */
    formatEmi3: (emi3, type) => {
        if (!emi3) return

        if (emi3.length !== 5) return emi3

        let formatedEmi3 = ''
        formatedEmi3 = type === 'CPO'
            ? `${emi3.slice(0, 2)}*${emi3.slice(2)}`
            : `${emi3.slice(0, 2)}-${emi3.slice(2)}`
        return formatedEmi3
    },
    /**
     * Removes emi3 format ( '*' or '-'' )
     * @param  {String} emi3 emi3
     * @returns {String} unformated emi3
     */
    unformatEmi3: (emi3) => {
        // eslint-disable-next-line no-useless-escape
        const unformatEmi3 = emi3.replace('\*', '').replace('\-', '')
        return unformatEmi3
    },

    /**
     * Check if country code emi3 is valid
     * @param  {String}  emi3 XXXXX
     * @param  {Boolean} withVirtual XE and XC is ok
     * @returns {Boolean}
     */
    emi3HasValidCountryCode: (emi3, withVirtual = false) => {
        const code = emi3.slice(0, 2)

        // XC for CPO, XE for EMSP
        if (withVirtual) return countryCodes.isValidCountryCode(code) || countryCodes.virtualCountryCodes.indexOf(code) >= 0

        return countryCodes.isValidCountryCode(code)
    },
    /**
     * Check if Emi3 is Valid (alphanumeric and length is 5)
     * @param {String} emi3 emi3
     * @returns {boolean} true / false
     */
    isValidEmi3 (emi3) {
        return helpers.isAlphaNumeric(emi3)
            && emi3.length === 5
            && helpers.emi3HasValidCountryCode(emi3, true)
    },

    /**
     * Check if operator type is an EMSP
     * @param {String} type type
     * @returns {boolean} true / false
     */
    isEmsp (type) {
        return type === 'EMSP'
    },

    isAlphaNumeric: (str) => {
        let code; let i; let
            len
        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i)
            if (!(code > 47 && code < 58) // numeric (0-9)
                && !(code > 64 && code < 91) // upper alpha (A-Z)
                && !(code > 96 && code < 123)) { // lower alpha (a-z)
                return false
            }
        }
        return true
    },
    isValidIP (ip) {
        // eslint-disable-next-line no-useless-escape
        const IP_REGEXP = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$|^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$|^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/
        return IP_REGEXP.test(ip)
    },
    isValidUrl (url) {
        // eslint-disable-next-line no-useless-escape
        const URL_REGEXP = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
        return URL_REGEXP.test(url)
    },
    /**
     * Handle error in ajax context (catch() in axios method)
     * @param {Object} error - error object return by request
     * @param {String} defaultErrorMsg - translated string
     * @returns {string}
     */
    handleError (error, defaultErrorMsg) {
        if (has(error, 'errors')) return error.errors[Object.keys(error.errors)[0]][0] // first field in errors object
        if (has(error, 'message') && error.message !== '') return error.message
        if (has(error, 'debugReason')) return error.debugReason
        if (has(error, 'reason')) return error.reason

        return defaultErrorMsg
    },

    /**
     * Returns params value from url
     * @param  {String} url url
     * @param  {String} param param
     * @returns {String} param value
     */
    getParamValue (url, param) {
        const urlObject = new URL(url)
        return urlObject.searchParams.get(param)
    },

    /**
     * Sets params value in url
     * @param {String} param param
     * @param {String} value value
     */
    setParamValue (param, value) {
        const urlObject = new URL(window.location.href)
        urlObject.searchParams.set(param, value)
        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, '', urlObject.href)
    },
    /**
     * Deletes param in url
     * @param {String} param param
     */
    deleteParam (param) {
        const urlObject = new URL(window.location.href)
        urlObject.searchParams.delete(param)
        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, '', urlObject.href)
    },

    /**
     * Vanilla equivalent of jQuery addClass() method
     * @param {object} node DOM Selector
     * @param {String} className Css class name
     */
    addClass (node, className) {
        if (node.classList) node.classList.add(className)
        else node.className += ` ${className}`
    },

    /**
     * Vanilla equivalent of jQuery removeClass() method
     * @param {object} node DOM Selector
     * @param {String} className Css class name to remove
     */
    removeClass (node, className) {
        if (node.classList) node.classList.remove(className)
        else node.className = node.className.replace(new RegExp(`(^|\\b)${className.split(' ').join('|')}(\\b|$)`, 'gi'), ' ')
    },

    /**
     * Return index of element in DOM (according to parent node)
     * Vanilla index() jQuery method
     * @param {object} node DOM Selector
     * @returns {Int}
     */
    indexInParent (node) {
        const children = node.parentNode.childNodes
        let num = 0

        // eslint-disable-next-line no-restricted-syntax
        for (const child of children) {
            if (child === node) return num

            if (child.nodeType === 1) num++
        }

        return -1
    },

    /**
     * Print a big number in a pretty way with commas and separators
     * eg: 187,121.12 (187121.12)
     * @param  {Integer} number number
     * @returns {String}
     */
    numberWithCommas (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

    /**
     * Get value of a specific cookie
     * @param {String} cookieName cookieName
     * @returns {string}
     */
    getCookie (cookieName) {
        const name = `${cookieName}=`

        const split = document.cookie.split(';')

        for (let i = 0; i < split.length; i++) {
            let cookie = split[i]
            while (cookie.charAt(0) === ' ') cookie = cookie.substring(1)

            if (cookie.indexOf(name) === 0) return cookie.substring(name.length, cookie.length)
        }

        return ''
    },
    /**
     * Return the country Code corresponding to the language
     * EN doesn't return any CSS flag for example
     * @param  {String} code "fr", "en", "de", ...
     * @returns {String} code "fr", "gb", "de", ...
     */
    confirmedLanguageCode (code) {
        return code === 'en'
            ? 'gb'
            : code
    },
    /**
     * Get VT local storage config
     * Note Jean 09/02/21: not used for now, remote later
     * @param {String} vtName value of "name" vue-table prop
     * @returns {Object|null} json object parsed
     */
    getVueTableStateConfig: (vtName) => {
        const lsVtKey = window.localStorage.getItem(`vuetables_${vtName}`) // key in LS of VT

        return lsVtKey
            ? JSON.parse(lsVtKey)
            : null
    },
    sortIssueEntities: (issue, withoutAuthenticNetworks = false) => {
        if (!issue) return []
        const connectors = issue?.relatedConnectors.map((connector) => ({
            type: resources.connector,
            entity: connector,
        }))

        const evses = issue?.relatedEvses.map((evse) => ({
            type: resources.evse,
            entity: evse,
        }))

        const lanDevices = issue?.relatedLanDevices.map((device) => ({
            type: resources.device,
            entity: device,
        }))

        const lans = issue?.relatedLans.map((lan) => ({
            type: resources.lan,
            entity: lan,
        }))

        const locations = issue?.relatedLocations.map((location) => ({
            type: resources.location,
            entity: location,
        }))

        const relatedNetworks = withoutAuthenticNetworks
            ? issue.relatedNetworks
            : issue.relatedAuthenticNetworks

        const networks = relatedNetworks.map((network) => ({
            type: resources.network,
            entity: network,
        }))

        return [...connectors, ...evses, ...lanDevices, ...lans, ...locations, ...networks]
    },
    getFileIcon: (fileName) => {
        let ext = /^.+\.([^.]+)$/.exec(fileName)
        ext = ext == null
            ? ''
            : ext[1]
        ext = ext.toLowerCase()
        switch (ext) {
        case 'zip': return 'file-archive'
        case 'pdf': return 'file-pdf'
        case 'jpeg': return 'file-image'
        case 'jpg': return 'file-image'
        case 'png': return 'file-image'
        case 'xlsx': return 'file-spreadsheet'
        case 'docx': return 'file-word'
        case 'csv': return 'file-csv'
        default: return 'file'
        }
    },
    isBenomadConnectorIdDeprecated: (id) => {
        const nId = +id

        // deprecated ranges to simplify final list
        if (nId >= 0 && nId < 30) return true
        if (nId >= 60 && nId < 65) return true

        // ...remaining ones
        const deprecatedList = [33, 56, 57, 59, 67]

        return deprecatedList.includes(nId)
    },
    getTypeName (type) {
        switch (type) {
        case resources.network: return 'Operator'
        case resources.location: return 'Location'
        case resources.evse: return 'Evse'
        case resources.connector: return 'Connector'
        case resources.lan: return 'Lan'
        case resources.device: return 'Lan-Device'
        default: return null
        }
    },
    // Simplified with 3 letter country code from: https://github.com/Kikobeats/country-vat
    vatFromCountry  (input) {
        if (input === null || input === undefined) return 0
        return rates[input] || 0
    },
    highlight (words, query) {
        const regExpQuery = new RegExp(query, 'ig')
        return words.toString().replace(regExpQuery, (matchedTxt) => `<b class="has-background-warning">${matchedTxt}</b>`)
    },
    truncate (text, length, clamp) {
        // eslint-disable-next-line no-param-reassign
        clamp = clamp || '...'
        const node = document.createElement('div')
        node.innerHTML = text
        const content = node.textContent
        return content.length > length
            ? content.slice(0, length) + clamp
            : content
    },
    formatDateFilter (value, withSec = false, keepMidnight = true) {
        if (value) {
            const result = dayjs(String(value))
                .format(helpers.getLocaleDateFormat(this.I18N.locale.toLowerCase(), withSec))

            return keepMidnight
                ? result
                : result.replace('00:00', '')
        }
        return 'error'
    },
    toFixed2 (value) {
        return value
            ? parseFloat(Number(value).toFixed(2))
            : 0
    },
    toCpoEmi3Id (value) {
        return helpers.isValidEmi3(value)
            ? helpers.formatEmi3(value, 'CPO')
            : ''
    },
    toEmspEmi3Id (value) {
        return helpers.isValidEmi3(value)
            ? helpers.formatEmi3(value, 'EMSP')
            : ''
    },
    /**
     * @param {String|Number} givenValue - The value to be formatted as currency.
     * @param {String} givenCurrency - The currency code.
     * @param {Number} maximumFractionDigits - The maximum number of fraction digits to display.
     * @returns {String} The formatted currency value.
     * Handled by default by NumberFormat according to currency and ISO 4217 list.
     * But in some cases we need to display more significant digits.
     */
    formatCurrency (givenValue, givenCurrency, maximumFractionDigits = 0) {
        let { locale, } = this.I18N
        if (locale.length === 2) {
            // "en" to "en-EN" for example
            locale = `${locale.toLowerCase()}-${locale.toUpperCase()}`
        }

        const currency = givenCurrency || DEFAULT_CURRENCY
        const value = Number.isNaN(givenValue)
            ? parseFloat(givenValue)
            : givenValue

        const numberFormatParams = {
            style: 'currency',
            currency,
            currencyDisplay: 'code',
        }
        if (maximumFractionDigits > 0) numberFormatParams.maximumFractionDigits = maximumFractionDigits

        return new Intl.NumberFormat(locale, numberFormatParams).format(value)
    },
    toKwh (value, withAdaptation = false) {
        const toKwh = value / 1000

        // Display as Wh if kWh is a small number
        return toKwh < 1 && withAdaptation
            ? `${value} Wh`
            : `${toKwh.toFixed(2)} kWh`
    },
    /*
        "Lorem ipsum: dolor" (en) | "Lorem ipsum : dolor" (other languages)
        Note jean 06/07/20 it is really dirty but it should be enough for now.
        Find a better way to handle this in a scallable way
    */
    withHyphen (value) {
        return value + (this.I18N.activeLocale === 'en'
            ? ': '
            : ' : ')
    },
    /**
    * Generate (if admin) BO link of resource, eg: .../backoffice/resources/sessions/541
    * @param {String} resource namespace of resource targeted ("sessions", "evses")
    * @param {String|Number} id or ref of resource targeted
    * @param {Boolean} isAdmin if the user is admin
    * @returns {String|null}
    */
    buildBackofficeLink (resource, id, isAdmin) {
        if (!isAdmin) return null

        const path = window.config.backoffice_path
        return `${window.location.protocol}//${window.location.hostname}/${path}/resources/${resource}/${id}`
    },
    /**
    * @param {String} ocppResponse OCPP CommandResponseType
    * @param {Function} t VueI18n instance
    * @returns {String|null} null if there is error message
    * Check each generic OCPP error and return a message according to response
    */
    handleOcppErrors (ocppResponse) {
        let errorMessage = null
        const responseTypes = window.config.ocpp.commandResponseTypes

        if (ocppResponse === responseTypes.NOT_SUPPORTED) errorMessage = toggleStore('devices.ocpp.notSupported')
        else if (ocppResponse === responseTypes.REJECTED) errorMessage = this.I18N.t('devices.ocpp.rejected')
        else if (ocppResponse === responseTypes.TIMEOUT) errorMessage = this.I18N.t('devices.ocpp.timeout')

        return errorMessage
    },

    humanizedOcppProtocol (protocol) {
        return this.I18N.t(`devices.protocol.${protocol.replace('.', '')}`)
    },

    setUrl (resource, ...params) {
        return new Url(resource, ...params).value
    },

    isAxiosSuccessReponse (response) {
        return response && [200, 201].indexOf(response.status) >= 0
    },

    deduceUserFullname (data, withEmail = true) {
        let fullname = ''

        if (data.firstname) {
            fullname = data.firstname

            if (data.lastname) fullname += ` ${data.lastname}`

            if (withEmail && data.email) fullname += ` (${data.email})`
        } else fullname = data.email

        return fullname
    },

    alert (i18nKey, type = 'success', args) {
        if (args?.isSnackbar) {
            new SnackbarProgrammatic().open({
                message: this.I18N.t(i18nKey, args?.translationParams || {}),
                type: `is-${type}`,
                position: 'is-bottom-right',
                indefinite: false,
                ...args,
            })
        } else {
            new ToastProgrammatic().open({
                type: `is-${type}`,
                message: this.I18N.t(i18nKey, args?.translationParams || {}),
                position: 'is-bottom',
                ...args,
            })
        }
    },

    getTodayDateMidnight () {
        const midnight = new Date()
        midnight.setHours(0, 0, 0, 0)
        return midnight
    },

    humanizeDuration (duration) {
        if (!dayjs.isDuration(duration)) {
            console.warn('params passed to humanize must be a dayjs duration object')
            return null
        }

        return duration.format(`M [${this.I18N.t('commons.durationUnits.months')}] D [${this.I18N.t('commons.durationUnits.days')}] H [${this.I18N.t('commons.durationUnits.hours')}] m [${this.I18N.t('commons.durationUnits.minutes')}]`)
    },

    mailto (users, issue) {
        let subject = ''
        let body = ''
        if (issue.id) {
            const url = `https://${window.location.hostname}${new Url(resources.fix, issue.id).value}`
            const title = issue.title.replace(/'/g, '&rsquo;')

            subject = this.I18N.t('fix.show.about', { id: issue.id, })
            body = this.I18N.t('fix.show.mailBody', { url, name: title, })
        }

        if (users && users.length) {
            const emails = users.map((user) => user.email)
            return `mailto:${emails.join(',')}?subject=${subject}&body=${body}`
        }
    },
    /**
    * Get active locale from user or browser or default from backend
    * @returns {String}
    */
    getActiveLocale () {
        let activeLocale = translations.getBrowserLanguage() || window.config.default_locale
        if (window.authUser && window.authUser.language !== null) activeLocale = window.authUser.language
        return activeLocale
    },
}

export default helpers
