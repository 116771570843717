import { defineStore, } from 'pinia'

const useMainStore = defineStore('main', {
    state: () => ({
        activeLink: null, // to emphase according link in header
        cdr: null,
        connector: null,
        contact: null,
        contactGroup: null,
        connectorLoading: false,
        cpos: [],
        defaultTariff: null,
        device: null,
        evse: null,
        issue: null,
        isVtLinkTargetBlank: false, // links in VT will be open in a new browser tab if true
        lan: null,
        lans: null,
        operator: null,
        operators: [],
        parent: null,
        scope: null,
        tariff: null,
        tariffs: [],
        location: {
            charging_when_closed: false, // default value
            country: 'FRA',
            latitude: null,
            longitude: null,
            directions: {}, // init directions trad object
            opening_times: {},
            type: 'UNKNOWN',
            time_zone: 'Europe/Paris',
        },
        locations: [],
        session: null,
        retrocessionGroup: null,
        stationCheck: null,
    }),
    actions: {
        setActiveLink (link) { this.activeLink = link },
        setCdr (cdr) { this.cdr = cdr },
        setStationCheck (stationCheck) { this.stationCheck = stationCheck },
        setConnector (connector) { this.connector = connector },
        setContact (contact) { this.contact = contact },
        setContactGroup (contactGroup) { this.contactGroup = contactGroup },
        setCpos (cpos) { this.cpos = cpos },
        setDefaultTariff (tariff) { this.defaultTariff = tariff },
        setDevice (device) { this.device = device },
        setEvse (evse) { this.evse = evse },
        setIssue (issue) { this.issue = issue },
        setLan (lan) { this.lan = lan },
        setLans (lans) { this.lans = lans },
        setLocation (location) { this.location = location },
        setLocations (locations) { this.locations = locations },
        setOperator (operator) { this.operator = operator },
        setParent (parent) { this.parent = parent },
        setScope (scope) { this.scope = scope },
        setSession (session) { this.session = session },
        setTariff (tariff) { this.tariff = tariff },
        setTariffs (tariffs) { this.tariffs = tariffs },
        setRetrocessionGroup (retrocessionGroup) { this.retrocessionGroup = retrocessionGroup },
        toggleConnectorLoading () { this.connectorLoading = !this.connectorLoading },
        toggleVtLinkTargetBlank () { this.isVtLinkTargetBlank = !this.isVtLinkTargetBlank },
    },
})

export default useMainStore
